import "../scss/style.scss";
class FontChanger {
  constructor() {
    this.buttonClass = ".font-changer__button";
    this.overlayClass = "font-changer__overlay";
    this.overlayActiveClass = "font-changer__overlay--active";

    this.buttons = document.querySelectorAll(this.buttonClass);
    this.overlay = document.createElement("div");
    this.overlay.classList.add(this.overlayClass);

    this.buttons.forEach((button) => {
      button.addEventListener("click", (e) => this.handleFontChange(e));
    });
  }
  handleFontChange(e) {
    const { overlay, overlayActiveClass } = this;
    if (document.body.querySelector(`.${this.overlayClass}`)) {
      overlay.classList.remove(overlayActiveClass);
    }
    document.body.appendChild(overlay);
    setTimeout(() => {
      overlay.classList.add(overlayActiveClass);
    }, 200);
    const transitionEndListener = () => {
      document.documentElement.setAttribute("font", e.target.dataset.font);
      overlay.classList.remove(overlayActiveClass);
      overlay.removeEventListener("transitionend", transitionEndListener);
      setTimeout(() => {
        document.body.removeChild(overlay);
      }, 500);
    };
    overlay.addEventListener("transitionend", transitionEndListener);
  }
}
class DougActivator {
  constructor() {
    this.dataSelector = "[data-doug]";
    this.dougActivators = document.querySelectorAll(`span${this.dataSelector}`);
    this.dougs = document.querySelectorAll(`img${this.dataSelector}`);
    this.inactiveClass = "intro__image--inactive";

    this.dougActivators.forEach((dougActivator) => {
      dougActivator.addEventListener("mouseenter", (e) => {
        this.handleDougActivation(e);
      });
    });
  }
  handleDougActivation(e) {
    const match = [...this.dougs].filter((doug) => {
      return doug.dataset.doug === e.target.dataset.doug;
    })[0];
    this.dougs.forEach((doug) => doug.classList.add(this.inactiveClass));
    match.classList.remove(this.inactiveClass);
  }
}
const dougActivator = new DougActivator();
const fontChanger = new FontChanger();

// Simple "lightbox" popup for images
const popimgs = document.querySelectorAll(".post__content img");
const popmodal = document.querySelector(".pop");
for (const popimg of popimgs) {
  const src = popimg.src;
  const alt = popimg.alt || "";
  popimg.onclick = () => {
    const img = popmodal.querySelector("img");
    img.src = src;
    img.alt = alt;
    popmodal.classList.add("show");
  }
}
popmodal.onclick = () => {
  popmodal.classList.remove("show");
}
document.onkeyup = (e) => {
  e = e || window.event;
  if (e.key === "Escape") {
    popmodal.classList.remove("show");
  }
};
